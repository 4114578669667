<template>
  <div style="position:relative;" class="document-input" :class="{'disabled': disabled, 'small': small}" >
    <div class="label" v-if="label"
         :class="{'label-error': !local_value && required}"
    >
      {{ label }}
    </div>
    <div style="position:relative;">
      <input type="text" :value="local_value" @input="$emit('input', $event.target.value)"
             :class="{'error-input': !local_value && required}"
             :disabled="disabled"
             :style="`min-height: ${minHeight}px; ${transparent ? 'background-color: transparent !important;' : ''}`"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "document_string_input",
  props: {
    field: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: String,
      default() {
        return ''
      }
    },
    row_num: {
      type: Number,
      default() {
        return 0
      }
    },
    required: {
      type: Boolean,
      default() {
        return true
      }
    },
    minHeight: {
      type: Number,
      default: 28
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    }
  },
  components: {
  },
  data() {
    return {
      local_value: this.value,
      active: false
    }
  },
  methods: {
    changeDate(payload) {
      this.$emit('input', payload.value)
      this.$emit('inputValue', { field: this.field, text: this.local_value, name: 'selectedRow' })
      // if (payload) {
      //   const row = this.table_items.find(item => item.row_num === payload.row_num)
      //   if (row) {
      //     row.pay_date = payload.value
      //   }
      //   this.markRowEdited(payload.row_num)
      // }
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: false,
      handler(payload) {
        this.local_value = payload
        this.$emit('inputValue', { field: this.field, text: this.local_value, name: 'selectedRow' })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>